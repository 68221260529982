<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      :model="dataForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-select
        v-model="typeValue"
        clearable
        placeholder="按类型筛选"
        @change="typeSelect"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in typeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="mutedValue"
        clearable
        placeholder="按是否禁言筛选"
        @change="mutedSelect"
        style="margin-right: 10px"
      >
        <el-option
          v-for="item in mutedOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!-- <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="编号"
      >
      </el-table-column>
      <el-table-column
        prop="companyName"
        header-align="center"
        align="center"
        label="所属代理商"
      >
      </el-table-column>
      <el-table-column
        prop="username"
        header-align="center"
        align="center"
        label="账号"
      >
      </el-table-column>
      <el-table-column
        prop="openid"
        header-align="center"
        align="center"
        label="微信openid"
      >
      </el-table-column>
      <el-table-column
        prop="nickName"
        header-align="center"
        align="center"
        label="昵称"
      >
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type == 0">超管</span>
          <span v-if="scope.row.type == 1">代理商管理员</span>
          <span v-if="scope.row.type == 2">司机</span>
          <span v-if="scope.row.type == 3">普通会员</span>
          <span v-if="scope.row.type == 4">商家管理员</span>
          <span v-if="scope.row.type == 5">推广者</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="roleName"
        header-align="center"
        align="center"
        label="角色"
      >
      </el-table-column>
      <el-table-column
        prop="phone"
        header-align="center"
        align="center"
        label="手机号"
      >
      </el-table-column>
      <el-table-column
        prop="realName"
        header-align="center"
        align="center"
        label="真实姓名"
      >
      </el-table-column>
      <el-table-column
        prop="property.integral"
        header-align="center"
        align="center"
        label="积分"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.property ? scope.row.property.integral / 100 : 0
          }}</span>
          <span
            @click="updateIntegral(scope.row.property.id)"
            style="color: rgb(180, 175, 255); cursor: pointer"
          >
            修改</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="isMuted"
        header-align="center"
        align="center"
        label="是否已禁言"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.isMuted == 0">否</span>
          <span v-if="scope.row.isMuted == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="driverStatus"
        header-align="center"
        align="center"
        label="状态"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.driverStatus }}</span> <br />
          <el-button
            v-if="scope.row.type == 2 && scope.row.driverStatus == '接单中'"
            type="text"
            size="small"
            @click="
              orderDetailHandle(
                scope.row.order,
                scope.row.lat,
                scope.row.lon,
                scope.row.business
              )
            "
            >查看详情</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.type == 2"
            type="text"
            size="small"
            @click="examineHandle(scope.row)"
            >资料审核</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog
      title="司机资料审核"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form :model="examineForm">
        <el-form-item label="状态" label-width="100px">
          <el-select
            v-model="examineForm.status"
            :placeholder="examinePlaceholder ? examinePlaceholder : '状态'"
            style="width: 100%"
          >
            <el-option
              v-for="item in examineOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <img
          style="width: 100%"
          v-for="item in userInfoImgs"
          :key="item"
          :src="'https://www.jsfgx.online/newcar/newcar/common/pic/' + item"
          alt=""
        />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="systemSubmit">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <!-- 弹窗,订单详情 -->
    <order-detail v-if="orderDetailVisible" ref="orderDetail"></order-detail>
  </div>
</template>

<script>
import AddOrUpdate from "./user-add-or-update";
import OrderDetail from "./order-detail.vue";
import {
  getUserList,
  deleteUserById,
  updateProperty,
  examineUser,
} from "../../api/api";
export default {
  data() {
    return {
      examinePlaceholder: "",
      userInfoImgs: [],
      userInfo: {},
      dialogFormVisible: false,
      examineForm: {
        id: "",
        status: "",
      },
      examineOptions: [
        {
          value: 1,
          label: "审核通过",
        },
        {
          value: 2,
          label: "审核不通过",
        },
      ],
      // 查询条件
      dataForm: {
        keyword: "",
        type: "",
        isMuted: "",
        cid: "",
      },
      // 类型选择项
      typeOptions: [
        {
          value: 1,
          label: "代理商管理员",
        },
        {
          value: 2,
          label: "司机",
        },
        {
          value: 3,
          label: "普通会员",
        },
        {
          value: 4,
          label: "商家管理员",
        },
        // {
        //   value: 5,
        //   label: "推广者",
        // },
      ],
      // 禁言选择项
      mutedOptions: [
        {
          value: 0,
          label: "未禁言",
        },
        {
          value: 1,
          label: "已禁言",
        },
      ],
      mutedValue: "", // 类型选择器绑定值
      typeValue: "", // 禁言选择器绑定值
      dataList: [], // 用户数据列表
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      addOrUpdateVisible: false,
      orderDetailVisible: false,
    };
  },
  components: {
    AddOrUpdate,
    OrderDetail,
  },
  activated() {
    this.getDataList();
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 审核
    examineHandle(item) {
      this.dialogFormVisible = true;
      this.examineForm.id = item.id;
      this.userInfo = item;
      this.userInfoImgs = item.dataPics.split("_");
      if (this.userInfo.dataStatus == 0) {
        this.examinePlaceholder = "申请中";
      } else if (this.userInfo.dataStatus == 1) {
        this.examinePlaceholder = "审核通过";
      } else if (this.userInfo.dataStatus == 2) {
        this.examinePlaceholder = "审核不通过";
      } else {
        this.examinePlaceholder = "未填写";
      }
    },
    // 提交审核
    systemSubmit() {
      examineUser(this.examineForm).then((data) => {
        if (data.code == 0) {
          this.$message({
            message: data.msg,
            type: "success",
            duration: 1500,
            onClose: () => {
              this.dialogFormVisible = false;
              this.getDataList();
            },
          });
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 修改积分
    updateIntegral(id) {
      this.$prompt("请输入积分，正数为增加，负数为减少", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          updateProperty({ id: id, integral: value * 100 }).then((data) => {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.cid) {
        this.dataForm.cid = userInfo.cid;
      }
      getUserList(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 类型选择框选择事件
    typeSelect(item) {
      this.dataForm.type = item;
    },
    // 禁言选择框选择事件
    mutedSelect(item) {
      this.dataForm.isMuted = item;
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },

    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 正在进行中的订单
    orderDetailHandle(order, driverLat, driverLon, business) {
      this.orderDetailVisible = true;
      this.$nextTick(() => {
        this.$refs.orderDetail.init(order, driverLat, driverLon, business);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteUserById({
          id,
        }).then((data) => {
          if (data && data.code === 0) {
            this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          } else {
            this.$message.error(data.msg);
          }
        });
      });
    },
  },
};
</script>
