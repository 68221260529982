<template>
  <el-dialog :title="!dataForm.id ? '新增' : '修改'" :close-on-click-modal="false" :visible.sync="visible" width="36%">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="220px">
      <el-form-item label="所属代理商" prop="cid">
        <el-select style="width: 100%" v-model="dataForm.cid" clearable placeholder="请输入关键词搜索" filterable
          @focus="companySelectFocus">
          <el-option v-for="item in companyOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="登录用户名" prop="username">
        <el-input v-model="dataForm.username" placeholder="登录用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input v-model="dataForm.password" placeholder="密码(添加用户或修改密码时才需输入)"></el-input>
      </el-form-item>
      <el-form-item label="昵称" prop="nickName">
        <el-input v-model="dataForm.nickName" placeholder="昵称"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="typeValue" clearable :placeholder="placeholder" @change="typeSelect" style="width: 100%">
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="dataForm.type == 2" label="分成比例" prop="proportion">
        <el-input v-model="dataForm.proportion" placeholder="如输入0.15就是分成15%，最多两位小数"></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="rid">
        <el-select style="width: 100%" v-model="dataForm.rid" clearable placeholder="请输入关键词搜索" filterable
          @focus="roleSelectFocus">
          <el-option v-for="item in roleOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="dataForm.phone" placeholder="手机号"></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" prop="realName">
        <el-input v-model="dataForm.realName" placeholder="真实姓名"></el-input>
      </el-form-item>
      <el-form-item label="推广一个洗养订单给多少钱" prop="realName">
        <el-input v-model="dataForm.popularizeOrdinary" placeholder="推广一个洗养订单给多少钱"></el-input>
      </el-form-item>
      <el-form-item label="推广一个代驾年检订单给多少钱" prop="realName">
        <el-input v-model="dataForm.popularizeNianjian" placeholder="推广一个代驾年检订单给多少钱"></el-input>
      </el-form-item>
      <el-form-item label="推广一个自驾年检订单给多少钱" prop="realName">
        <el-input v-model="dataForm.popularizeZijia" placeholder="推广一个自驾年检订单给多少钱"></el-input>
      </el-form-item>
      <el-form-item label="渠道价格" prop="qudaoFuwufei">
        <el-input v-model="dataForm.qudaoFuwufei" placeholder="渠道价格"></el-input>
      </el-form-item>
      <el-form-item label="是否禁言" prop="isMuted">
        <el-switch v-model="mutedValue" active-text="是" inactive-text="否" @change="mutedChange">
        </el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getUserInfo,
  updateUser,
  getCompanyList,
  getRoleList,
} from "../../api/api";
export default {
  data() {
    return {
      visible: false,
      companyName: "", // 代理商名回显
      roleName: "", // 角色名回显
      dataForm: {
        id: 0,
        cid: "",
        bid: "",
        rid: "",
        username: "",
        password: "",
        openid: "",
        nickName: "",
        type: "",
        phone: "",
        picture: "",
        realName: "",
        tradePassword: "",
        isMuted: 0,
        lon: "",
        lat: "",
        popularizeOrdinary: "",
        popularizeNianjian: "",
        popularizeZijia: "",
        createDate: "",
        updateDate: "",
        isDeleted: "",
        proportion: "",
        qudaoFuwufei: "",
      },
      placeholder: "请选择类型",
      typeValue: "", // 类型选择器绑定值
      mutedValue: false, // 禁言开关绑定值
      // 类型选择项
      typeOptions: [],
      companyOptions: [], // 代理商列表选择列表
      roleOptions: [], // 角色列表选择列表
      dataRule: {
        nickName: [
          { required: true, message: "昵称不能为空", trigger: "blur" },
        ],
        type: [
          {
            required: true,
            message: "类型不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 角色选择器获得焦点事件
    roleSelectFocus() {
      // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // if (
      //   (this.dataForm.cid == "" || this.dataForm.cid == null) &&
      //   userInfo.type != 0
      // ) {
      //   this.$message.error("请先选择代理商");
      //   return;
      // }

      this.dataListLoading = true;
      getRoleList({ limit: 99999, cid: this.dataForm.cid }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          this.roleOptions = [];
          if (resultList.length > 0) {
            for (let item of resultList) {
              this.roleOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
        }
        this.dataListLoading = false;
      });
    },
    // 代理商选择器获得焦点事件
    companySelectFocus() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.cid) {
        return;
      }

      this.dataListLoading = true;
      getCompanyList({ limit: 9999 }).then((data) => {
        if (data && data.code === 0) {
          let resultList = data.data.list;
          this.companyOptions = [];
          if (resultList.length > 0) {
            this.companyOptions.push({
              value: "",
              label: "总部",
            });
            for (let item of resultList) {
              this.companyOptions.push({
                value: item.id,
                label: item.name,
              });
            }
          }
          this.roleSelectFocus();
        }
        this.dataListLoading = false;
      });
    },
    // 是否禁言开关回调
    mutedChange(item) {
      if (item) {
        this.dataForm.isMuted = 1;
      } else {
        this.dataForm.isMuted = 0;
      }
    },
    // 类型选择器选择回调
    typeSelect(item) {
      this.dataForm.type = item;
    },

    init(id) {
      // 初始化回显数据
      this.dataForm.id = id || 0;
      this.visible = true;

      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();

        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo.cid) {
          this.companyOptions.push({
            value: userInfo.company.id,
            label: userInfo.company.name,
          });
          this.dataForm.cid = userInfo.company.id;
        } else {
          this.companySelectFocus();
        }

        if (this.dataForm.id) {
          getUserInfo({ id: this.dataForm.id }).then((data) => {
            if (data && data.code === 0) {
              this.dataForm = data.data;

              if (this.dataForm.popularizeOrdinary) {
                this.dataForm.popularizeOrdinary =
                  this.dataForm.popularizeOrdinary / 100;
              }
              if (this.dataForm.popularizeNianjian) {
                this.dataForm.popularizeNianjian =
                  this.dataForm.popularizeNianjian / 100;
              }
              if (this.dataForm.popularizeZijia) {
                this.dataForm.popularizeZijia =
                  this.dataForm.popularizeZijia / 100;
              }
              if (this.dataForm.qudaoFuwufei) {
                this.dataForm.qudaoFuwufei =
                  this.dataForm.qudaoFuwufei / 100;
              }

              if (this.dataForm.type === 0) {
                this.placeholder = "超管";
              } else if (this.dataForm.type === 1) {
                this.placeholder = "代理商管理员";
              } else if (this.dataForm.type === 2) {
                this.placeholder = "司机";
              } else if (this.dataForm.type === 3) {
                this.placeholder = "普通用户";
              } else if (this.dataForm.type === 4) {
                this.placeholder = "商家管理员";
              }

              if (userInfo.cid) {
                this.dataForm.cid = userInfo.cid;
                this.typeOptions = [
                  {
                    value: 2,
                    label: "司机",
                  },
                  {
                    value: 3,
                    label: "普通会员",
                  },
                  // {
                  //   value: 4,
                  //   label: "商家管理员",
                  // },
                ];
              } else {
                this.typeOptions = [
                  // {
                  //   value: 1,
                  //   label: "代理商管理员",
                  // },
                  {
                    value: 2,
                    label: "司机",
                  },
                  {
                    value: 3,
                    label: "普通会员",
                  },
                  // {
                  //   value: 4,
                  //   label: "商家管理员",
                  // },
                ];
              }
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      if (this.dataForm.proportion > 1 || this.dataForm.proportion < 0) {
        this.$message.error("请正确输入分成比例");
        return;
      }
      if (this.dataForm.qudaoFuwufei && this.dataForm.qudaoFuwufei < 0) {
        this.$message.error("请正确输入渠道价格");
        return;
      }
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let requestDate = this.dataForm;
          if (requestDate.popularizeOrdinary) {
            requestDate.popularizeOrdinary =
              requestDate.popularizeOrdinary * 100;
          }
          if (requestDate.popularizeNianjian) {
            requestDate.popularizeNianjian =
              requestDate.popularizeNianjian * 100;
          }
          if (requestDate.popularizeZijia) {
            requestDate.popularizeZijia = requestDate.popularizeZijia * 100;
          }
          if (requestDate.qudaoFuwufei) {
            requestDate.qudaoFuwufei = requestDate.qudaoFuwufei * 100;
          }
          if (this.dataForm.id) {
            // 修改
            updateUser(requestDate).then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "修改操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$emit("refreshDataList");
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
          }
        }
      });
    },
  },
};
</script>
